import React, { useCallback, useState } from "react";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBValidation,
  MDBInput,
  MDBBtn,
} from "mdb-react-ui-kit";
import logo from "../assets/logo.png";
import { loginReq } from "../Request";
import useStore from "../Store";

export default function () {
  const set = useStore((s) => s.set);
  const [formState, setFormState] = useState({
    email: "",
    password: "",
  });

  const [hasError, setHasError] = useState(false);

  const login = async (data) => {
    const loginResult = await loginReq(data);
    if (loginResult.error) {
      setHasError(true);
      return;
    }
    set((s) => {
      s.profile = true;
      s.access_token = loginResult.access_token;
    });
  };

  const onLoginSubmit = useCallback(() => {
    if (!formState.email || !formState.password) {
      return;
    }
    login(formState);
  }, [formState]);

  const onChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value.trim() });
  };

  return (
    <section className="min-vh-100 d-flex align-items-center justify-content-center">
      <MDBContainer>
        <MDBRow className="justify-content-center">
          <MDBCol size="4">
            <div className="shadow-5 rounded-3 p-5 bg-white text-start">
              <div className="mb-3">
                <img src={logo} style={{ width: "100%" }} />
              </div>
              <MDBValidation
                className="row g-3"
                noValidate
                onSubmit={onLoginSubmit}
              >
                <MDBInput
                  name="email"
                  type="email"
                  id="validationCustom01"
                  required
                  label="Email"
                  id="validationCustom01"
                  onChange={onChange}
                  value={formState.email}
                  size="sm"
                />

                <MDBInput
                  name="password"
                  id="validationCustom01"
                  type="password"
                  required
                  label="Password"
                  id="validationCustom02"
                  onChange={onChange}
                  value={formState.password}
                  size="sm"
                />
                {hasError && (
                  <p className="text-danger m-0">Wrong login details!</p>
                )}
                <MDBBtn
                  type="submit"
                  className="fw-bold btn-pink"
                  
                >
                  Login
                </MDBBtn>
              </MDBValidation>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </section>
  );
}
